import React from 'react';

import NowIcon from 'vectors/internal/now.svg';
import { StaticText } from 'common/typography';

import {
  BlogArticleDurationContainer,
  IconContainer,
} from './styled';

export const BlogArticleDuration: React.FC<BlogArticleDurationProps> = ({
  duration,
}) => {
  return (
    <BlogArticleDurationContainer>
      <IconContainer>
        <NowIcon />
      </IconContainer>
      <StaticText $size="sm" $margin="0">
        {duration}
      </StaticText>
    </BlogArticleDurationContainer>
  );
};

type BlogArticleDurationProps = {
  duration: string;
};
