export const blogArticleImageVariants = {
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
  },
  animate: {
    y: 0,
    x: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
      type: 'spring',
      bounce: 0,
    },
  },
  exitLeft: {
    opacity: 0,
    x: '-20%',
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.5,
    },
  },
  exitRight: {
    opacity: 0,
    x: '20%',
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.5,
    },
  },
};

export const blogArticleItemVariants = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
  },
};
