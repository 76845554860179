import * as React from 'react';

import { StaticButtonText } from 'common/typography';

import { CategoryArticleCountType } from '../BlogSection';
import { BlogOverviewCategoryFilterTag } from '../';
import { BlogOverviewCategoryFilterContainer, CategoryTagWrapper } from './styled';

export const BlogOverviewCategoryFilter: React.FC<BlogOverviewCategoryFilterProps> = ({
  categoryArticleCount, activeCategory, onClickCategoryTag,
}) => {
  return (
    <BlogOverviewCategoryFilterContainer>
      <StaticButtonText>Popular items</StaticButtonText>
      <CategoryTagWrapper>
        {
          Object.keys(categoryArticleCount).map((category: string) => {
            if (category === 'all') return null;

            return (
              <BlogOverviewCategoryFilterTag
                category={category}
                articleCount={categoryArticleCount[category]}
                active={activeCategory === category}
                onClick={onClickCategoryTag}
                key={category}
              />
            );
          })
        }
      </CategoryTagWrapper>
    </BlogOverviewCategoryFilterContainer>
  );
};

type BlogOverviewCategoryFilterProps = {
  categoryArticleCount: CategoryArticleCountType;
  activeCategory: string;
  onClickCategoryTag: (category: string) => void;
};
