import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const BlogMotionParallaxImageWrapper = styled(motion.div)<BlogMotionParallaxWrapperProps>`
  max-height: 40vh;
  width: 100%;

  .motion_image {
    min-height: 50vh;
    border-radius: inherit;
    width: 100%;
    max-height: inherit;

    ${({ hoverable }) => hoverable && css`
      transition: 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    `}
  }

  ${({ size }) => size === 'xs' && css`
    ${media.desktop`
      max-height: 40vh;

      .motion_image {
        min-height: 50vh;
      }
    `}

    ${media.large`
      max-height: 30vh;

      .motion_image {
        min-height: 37.5vh;
      }
    `}
  `}

  ${({ size }) => size === 'sm' && css`
    ${media.desktop`
      max-height: 40vh;

      .motion_image {
        min-height: 50vh;
      }
    `}

    ${media.large`
      max-height: 50vh;

      .motion_image {
        min-height: 60vh;
      }
    `}
  `}

  ${({ size }) => size === 'default' && css`
    ${media.desktop`
      max-height: 50vh;

      .motion_image {
        min-height: 60vh;
      }
    `}

    ${media.large`
      max-height: 60vh;

      .motion_image {
        min-height: 70vh;
      }
    `}
  `}
`;

type BlogMotionParallaxWrapperProps = {
  size?: 'xs' | 'sm' | 'default';
  hoverable?: boolean;
};
