import * as i from 'types';
import React, { useState, useEffect, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  BlogFeaturedArticle,
  BlogOverviewPagination,
  BlogOverviewCategoryFilter,
  BlogOverviewArticleList,
} from '..';

import { BlogSectionContainer, ExtendedHeading } from './styled';

export const BlogSection: React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filter, setFilter] = useState({
    category: 'all',
  });

  const defaultFilter = {
    category: 'all',
  };

  // Scroll to top of window when navigate across pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Number of articles per page (excl. featured article)
  const pageSize = 5;

  const paginateArticles = (articles: i.BlogArticleType[]) => {
    const pages = [];
    for (let i = 0; i < articles.length + 1; i += pageSize) {
      pages.push(articles.slice(i, i + pageSize));
    }
    return pages;
  };

  const data = useStaticQuery<GatsbyTypes.BlogArticlesQuery>(query);
  const allArticles = data.allContentfulArticleWeb.nodes as unknown as i.BlogArticleType[];
  const { featuredArticle, articles } = React.useMemo(() => {
    return {
      featuredArticle: allArticles.find((article) => article.isFeatured),
      articles: allArticles.filter((article) => !article.isFeatured),
    };
  }, [allArticles]);

  // Pre-categorize articles into lists
  const articleCollection = { 'all': articles } as ArticleCollectionType;
  for (const article of allArticles) {
    const { category } = article;
    for (const cat of category) {
      if (!articleCollection[cat]) {
        articleCollection[cat] = [article];
      } else {
        articleCollection[cat] = [...articleCollection[cat], article];
      }
    }
  }

  const getCategoryArticleCount = (articleCollection: ArticleCollectionType) => {
    let categoryArticleCount = {} as CategoryArticleCountType;

    Object.keys(articleCollection).map((category) => {
      return categoryArticleCount[category] = articleCollection[category].length;
    });

    // Sort categories by article count (Descending)
    categoryArticleCount = Object.entries(categoryArticleCount)
      .sort((entryA, entryB) => {
        return entryB[1] - entryA[1];
      })
      .reduce((sortedCategoryArticleCount, [category, articleCount]) => ({
        ...sortedCategoryArticleCount,
        [category]: articleCount,
      }), {});

    return categoryArticleCount;
  };

  const onClickCategoryTag = (category: string) => {
    if (category === filter.category) {
      setFilter({ ...filter, category: defaultFilter.category });
    } else {
      setFilter({ ...filter, category });
    }
  };

  const categoryArticleCount = getCategoryArticleCount(articleCollection);
  const filteredArticles = articleCollection[filter.category];
  const pages = useMemo(() => paginateArticles(filteredArticles), [filteredArticles]);

  return (
    <BlogSectionContainer>
      <ExtendedHeading>BLOG</ExtendedHeading>
      <BlogOverviewCategoryFilter
        activeCategory={filter.category}
        onClickCategoryTag={onClickCategoryTag}
        categoryArticleCount={categoryArticleCount}
      />
      {currentPage === 1 && featuredArticle && filter.category === defaultFilter.category && (
        <BlogFeaturedArticle
          article={featuredArticle}
        />
      )}
      <BlogOverviewArticleList articles={pages[currentPage - 1]} />
      <BlogOverviewPagination
        currentPage={currentPage}
        articleCount={articleCollection[filter.category].length}
        onClickNavigate={(pageNumber) => setCurrentPage(pageNumber)}
        pageSize={pageSize}
      />
    </BlogSectionContainer>
  );
};

const query = graphql`
  query BlogArticles {
    allContentfulArticleWeb(sort: { fields: createdAt, order: DESC }) {
      distinct(field: category)
      nodes {
        category
        bannerImage {
          gatsbyImageData
          id
        }
        id
        previewText
        slug
        duration
        title
        isFeatured
      }
    }
  }
`;

export type CategoryArticleCountType = {
  [value: string]: number;
};

type ArticleCollectionType = {
  [category: string]: i.BlogArticleType[];
};
