import * as i from 'types';
import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BLOCKS, Node } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { StaticText, StaticBlockQuote, Headline3 } from 'common/typography';
import {
  BlogPageTextWidthImageBlock, BlogPageFullWidthImageBlock, BlogPageDoubleImageBlock, BlogPageVideoBlock,
} from '../';

import { BlogPageBodyContainer, BlogPageBodyTextContainer, BlogPageBodyList } from './styled';

export const BlogPageBody: React.FC<BlogPageBodyProp> = ({ content }) => {
  const { raw, references } = content.content;
  const documentContent = JSON.parse(raw);
  const contentAssetRefs = references;

  // Create a mapping between rich text linked assets to actual asset data
  const createAssetMapping = (document: Document, refs: AssetRefType[]) => {
    const mapping = {} as AssetEntryMappingType;
    document.content.forEach((contentBlock: ContentBlockType) => {
      if (contentBlock.nodeType === 'embedded-entry-block') {
        const { id } = contentBlock.data.target.sys;
        mapping[id] = refs.find((ref: AssetRefType) => {
          return ref.contentful_id === id;
        })!;
      }
    });
    return mapping;
  };

  const assetMapping = React.useMemo(() => createAssetMapping(documentContent, contentAssetRefs), [documentContent]);

  const richTextOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <Headline3 $margin="56px 0 0 0;" $uppercase>{children}</Headline3>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.QUOTE]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <StaticBlockQuote>{children}</StaticBlockQuote>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.HEADING_6]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <StaticText $size="sm" $isCentered $color="gray">{children}</StaticText>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <StaticText $size="lg">{children}</StaticText>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.UL_LIST]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <BlogPageBodyList as="ul">{children}</BlogPageBodyList>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.OL_LIST]: (node: Node, children: React.ReactNode) => (
        <BlogPageBodyTextContainer>
          <BlogPageBodyList>{children}</BlogPageBodyList>
        </BlogPageBodyTextContainer>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
        const { id } = node.data.target.sys;
        const entryRef = assetMapping[id];

        if (!entryRef) return;

        switch (entryRef.__typename) {
          case 'ContentfulArticleWebVideo':
            const { youtubeUrl, name } = entryRef;

            return <BlogPageVideoBlock {...{ youtubeUrl, name }} />;
          case 'ContentfulArticleWebImage':
            const { style, asset, reference, isParallax } = entryRef;

            switch (style) {
              case 'text-width':
                return (
                  <BlogPageTextWidthImageBlock
                    image={asset[0].gatsbyImageData}
                    imageRef={reference} parallax={isParallax}
                  />
                );
              case 'full-width':
                return (
                  <BlogPageFullWidthImageBlock
                    image={asset[0].gatsbyImageData}
                    imageRef={reference}
                    parallax={isParallax} />
                );
              case 'double-block':
              default:
                const images = asset.map((image) => image.gatsbyImageData) as IGatsbyImageData[];
                return (
                  <BlogPageDoubleImageBlock
                    images={images}
                    imageRefs={reference}
                    parallax={isParallax}
                  />
                );
            }
          default:
            return null;
        }

      },
    },
  };

  return (
    <div className='prose max-w-none'>
      <BlogPageBodyContainer>
        {documentToReactComponents(documentContent, richTextOptions)}
      </BlogPageBodyContainer>
    </div>
  );
};

type BlogPageBodyProp = {
  content: Pick<i.ComponentBodyText, 'content'>;
};

type ContentBlockType = {
  nodeType: string;
  data: {
    target: {
      sys: {
        id: string;
      }
    }
  }
};

type AssetEntryMappingType = {
  [id: string]: AssetRefType;
};

type AssetRefType = ImageEntryReferenceType | VideoEntryReferenceType;

type ImageEntryReferenceType = {
  contentful_id: string;
  style: string;
  reference: string[];
  asset: ImageEntryAssetType[];
  isParallax: boolean;
  __typename: 'ContentfulArticleWebImage';
};

type VideoEntryReferenceType = {
  __typename: 'ContentfulArticleWebVideo';
  youtubeUrl: string;
  name: string;
  contentful_id: string;
};

type ImageEntryAssetType = {
  gatsbyImageData: IGatsbyImageData;
};
