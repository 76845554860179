import styled from 'styled-components';

import { media } from 'styles/utils';

import { BlogMotionImageBlock } from '../BlogMotionImageBlock';

export const AuthorBlockWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-bottom: 80px;

  ${media.desktop`
    max-width: 1400px;
  `}
`;

export const AuthorBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${media.desktop`
    flex-direction: row;
    max-width: 800px;
    margin: 0 auto;
  `}
`;

export const AuthorImage = styled(BlogMotionImageBlock)`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 0;

  .motion_image {
    min-height: 10vh;
  }
`;

export const AuthorNameContainer = styled.div`
  text-align: center;

  ${media.desktop`
    text-align: left;
  `}
`;
