import * as i from 'types';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTransform, useMotionTemplate } from 'framer-motion';
import { Link } from 'gatsby';

import { useScrollDistanceForElementInView } from 'services/hooks';
import { StaticText } from 'common/typography';

import { BlogMotionImageBlockContainer } from '../BlogMotionImageBlock/styled';
import { BlogMotionParallaxImageWrapper } from './styled';

export const BlogMotionParallaxImageBlock: React.FC<i.BlogMotionImageBlockProps> = ({
  image, imageRef, size = 'default', margin, borderRadius, hoverable = true, clickable,
  slug, children, className, onClick,
}) => {
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();
  const imagePositionY = useTransform(scrolledDistance, [0, 1], [0, -10]);
  const templateImagePositionY = useMotionTemplate`translateY(${imagePositionY}vh)`;

  const ContainerProps = {
    ref: elementRef,
    className,
    margin,
    borderRadius,
  };

  const ImageWrapperProps = {
    size,
    hoverable,
  };

  return (
    <>
      <BlogMotionImageBlockContainer {...ContainerProps}>
        {children}
        <BlogMotionParallaxImageWrapper style={{ transform: templateImagePositionY }} {...ImageWrapperProps}>
          {clickable && slug &&
            <Link to={slug} onClick={onClick}>
              <GatsbyImage
                image={getImage(image)!}
                alt="thumbnail"
                objectFit="cover"
                className="motion_image"
              />
            </Link>
          }
          {!clickable && (
            <GatsbyImage
              image={getImage(image)!}
              alt="thumbnail"
              objectFit="cover"
              className="motion_image"
            />
          )}
        </BlogMotionParallaxImageWrapper>
      </BlogMotionImageBlockContainer>
      {imageRef && <StaticText $size="sm" $color="gray" $margin="14px 0 0" $isCentered>{imageRef}</StaticText>}
    </>
  );
};
