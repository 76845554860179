import * as i from 'types';
import * as React from 'react';

import { useDevice } from 'services/hooks';
import { StaticText, StaticHeading } from 'common/typography';
import { BlogArticleCategory, BlogArticleDuration, BlogReadMoreButton } from '../';

import {
  BlogArticleBannerImage,
  BlogArticleContainer,
  BlogArticleTextBlock,
} from './styled';

export const BlogInterestingArticle: React.FC<i.BlogArticleProps> = ({ article }) => {
  const { isMobile } = useDevice();
  const { bannerImage, category, duration, title, previewText, slug } = article;
  const detailPath = `/${slug}`;

  return (
    <BlogArticleContainer>
      <BlogArticleBannerImage size="xs" image={bannerImage} slug={detailPath} clickable>
        <BlogArticleCategory categories={category} />
      </BlogArticleBannerImage>
      <BlogArticleTextBlock>
        <BlogArticleDuration {... { duration }} />
        <StaticHeading as={isMobile ? 'h4' : 'h3'} $margin="0" $uppercase>{title}</StaticHeading>
        <StaticText $size="lg" $margin="0">{previewText}</StaticText>
        <BlogReadMoreButton path={detailPath} />
      </BlogArticleTextBlock>
    </BlogArticleContainer>
  );
};
