import * as i from 'types';
import * as React from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';

import { useDevice } from 'services/hooks';
import { StaticText, StaticHeading } from 'common/typography';
import { BlogArticleCategory, BlogArticleDuration, BlogReadMoreButton } from '../';

import { blogArticleImageVariants, blogArticleItemVariants } from './motion';
import {
  BlogArticleContainer,
  BlogArticleTextBlock,
  BannerImageMotionContainer,
  BlogArticleBannerImage,
  BannerTextMotionContainer,
} from './styled';

export const BlogArticle: React.FC<i.BlogArticleProps> = ({ article, reversed = false }) => {
  const { isMobile } = useDevice();
  const controlsImage = useAnimation();
  const controlsText = useAnimation();
  const { bannerImage, category, duration, title, previewText, slug } = article;
  const detailPath = `/${slug}`;

  const onClickBlogArticle = () => {
    controlsImage.start(reversed ? 'exitLeft' : 'exitRight');
    controlsText.start(reversed ? 'exitRight' : 'exitLeft');
  };

  return (
    <BlogArticleContainer isReversed={reversed}>
      <AnimatePresence>
        <BannerImageMotionContainer variants={blogArticleImageVariants} animate={controlsImage} key={`blog_article_${slug}`}>
          <BlogArticleBannerImage
            image={bannerImage}
            margin="0"
            slug={detailPath}
            size="sm"
            clickable
            hoverable={true}
            onClick={onClickBlogArticle}
          >
            <motion.div variants={blogArticleItemVariants}>
              <BlogArticleCategory categories={category} />
            </motion.div>
          </BlogArticleBannerImage>
        </BannerImageMotionContainer>
      </AnimatePresence>
      <BannerTextMotionContainer variants={blogArticleImageVariants} animate={controlsText}>
        <BlogArticleTextBlock>
          <BlogArticleDuration duration={duration} />
          <StaticHeading as={isMobile ? 'h4' : 'h3'} $margin="0" $uppercase>{title}</StaticHeading>
          <StaticText $size="lg" $margin="0">{previewText}</StaticText>
          <BlogReadMoreButton path={detailPath} onClick={onClickBlogArticle} />
        </BlogArticleTextBlock>
      </BannerTextMotionContainer>
    </BlogArticleContainer>
  );
};
