import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { StaticHeading, StaticText } from 'common/typography';

import {
  AuthorBlockContainer, AuthorNameContainer, AuthorBlockWrapper,
  AuthorImage,
} from './styled';

export const BlogPageAuthorBlock: React.FC<BlogPageAuthorBlockProps> = ({ author }) => {
  if (!author) return null;
  const { authorImage, authorName, authorEmail, authorRole } = author;

  return (
    <AuthorBlockWrapper>
      <AuthorBlockContainer>
        <AuthorImage image={authorImage} size="xs" hoverable={false} />
        <AuthorNameContainer>
          <StaticHeading as="h2" $margin="0" $uppercase $isCentered>{authorName}</StaticHeading>
          <StaticText $size="lg" $margin="16px 0 8px">{authorRole}</StaticText>
          <StaticText $margin="0">
            <a href={`mailto:${authorEmail}`}>{authorEmail}</a>
          </StaticText>
        </AuthorNameContainer>
      </AuthorBlockContainer>
    </AuthorBlockWrapper>
  );
};

type BlogPageAuthorBlockProps = {
  author: i.AuthorType;
};

export const query = graphql`
  fragment BlogPageAuthorFragment on ContentfulArticleWeb {
    author {
      id
      authorEmail
      authorName
      authorRole
      authorImage {
        gatsbyImageData
        id
      }
    }
  }
`;
