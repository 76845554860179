import * as React from 'react';

import {
  BlogOverviewPaginationActionButton,
  BlogOverviewPaginationButton,
} from '..';
import { BlogOverviewPaginationMoreButton } from '../BlogOverviewPaginationButton';
import { BlogOverviewPaginationContainer } from './styled';

export const BlogOverviewPagination: React.FC<BlogOverviewPaginationProps> = ({
  currentPage, articleCount, pageSize = 5, onClickNavigate,
}) => {
  const minPage = 1;
  const maxPage = Math.ceil(articleCount / pageSize);
  const siblingPageCount = 1;

  const getPagination = (minPage: number, maxPage: number, currentPage: number,
    siblingPageCount: number,
  ) => {
    let pages = [];
    const lowerBound = currentPage === maxPage ?
      currentPage - siblingPageCount - 1 : currentPage - siblingPageCount;
    const upperBound = currentPage === 1 ?
      currentPage + siblingPageCount + 1 : currentPage + siblingPageCount;

    for (let i = lowerBound; i < upperBound + 1; i++) {
      if (i >= minPage && i <= maxPage) {
        pages.push(i);
      }
    }

    pages = addDots(pages, minPage, siblingPageCount);

    return pages;
  };

  const addDots = (pageRange: number[], currentPage: number, siblingPageCount: number) => {
    const firstPage = pageRange[0];
    const lastPage = pageRange[pageRange.length - 1];
    let newPageRange = [...pageRange];

    if (currentPage - siblingPageCount > firstPage) {
      // Use -1 to indicate ...
      newPageRange = [-1, ...newPageRange];
    }

    if (currentPage + siblingPageCount < lastPage) {
      newPageRange = [...newPageRange, -1];
    }

    return newPageRange;
  };

  const pageRange = getPagination(minPage, maxPage, currentPage, siblingPageCount);
  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <BlogOverviewPaginationContainer>
      <BlogOverviewPaginationActionButton
        disabled={currentPage === 1}
        isPrevious={true}
        onClick={() => onClickNavigate(previousPage)}
        key="previousPage"
      />
      {pageRange.map((page) => {
        return (
          page === -1 ?
            <BlogOverviewPaginationMoreButton /> :
            <BlogOverviewPaginationButton
              active={currentPage === page}
              pageNumber={page}
              onClick={() => onClickNavigate(page)}
              key={page}
            />
        );
      })}
      <BlogOverviewPaginationActionButton
        disabled={currentPage === maxPage}
        isPrevious={false}
        onClick={() => onClickNavigate(nextPage)}
        key="nextPage"
      />
    </BlogOverviewPaginationContainer>
  );
};


type BlogOverviewPaginationProps = {
  currentPage: number;
  articleCount: number;
  pageSize?: number
  onClickNavigate: (pageNumber: number) => void;
}
