import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { InternalLink } from 'common/interaction';
import { Text } from 'common/typography';

export const ButtonContainer = styled(motion(InternalLink))`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 180px;
  margin-bottom: 32px;
  position: relative;

  ${media.desktop`
    margin-bottom: 0;
  `}
`;

export const ExtendedText = styled(motion(Text))`
  font-size: 16px;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 3.25em;
  transform: translateY(-50%);
`;

export const IconContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.black};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: white;
  display: flex;
  padding: 0 0 0 12px;
  align-items: center;

  svg {
    transform: rotate(180deg);
  }
`;
