import styled from 'styled-components';

import {
  TextAndImageBlockExtraFieldsContainer,
  TextAndImageBlockExtraFieldsText,
} from 'modules/contentBlocks';
import { media } from 'styles/utils';
import { BlogMotionImageBlock } from '../BlogMotionImageBlock';

export const BlogArticleContainer = styled(TextAndImageBlockExtraFieldsContainer)`
  margin: 0;
  width: 100%;

  ${media.desktop`
    gap: 1.5vw;
    margin-bottom: 12vh;
    display: flex;
    flex-direction: column-reverse;
  `}
`;

export const BlogArticleTextBlock = styled(TextAndImageBlockExtraFieldsText)`
  gap: 16px;
  margin-top: 32px;

  ${media.desktop`
    width: 100%;
    margin-top: 0;
  `};
`;

export const BlogArticleBannerImage = styled(BlogMotionImageBlock)`
  border-radius: 16px;
  width: 100%;
  max-height: 300px;

  .motion_image {
    min-height: 300px;
  };

  order: 2;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
`;
