import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { BlogMotionParallaxImageBlock } from '..';
import { FullWidthImageBlock } from './styled';

export const BlogPageFullWidthImageBlock: React.FC<BlogPageFullWidthImageBlockProps> = ({ image,
  imageRef, parallax,
}) => {
  const ImageBlockProps = {
    image,
    imageRef,
    margin: '80px 0 0',
    hoverable: false,
  };

  if (parallax) {
    return <FullWidthImageBlock as={BlogMotionParallaxImageBlock} {...ImageBlockProps} />;
  }

  return (
    <FullWidthImageBlock {...ImageBlockProps} />
  );
};

type BlogPageFullWidthImageBlockProps = {
  image: IGatsbyImageData;
  imageRef: string[];
  parallax: boolean;
};
