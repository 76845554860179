import * as React from 'react';

import { StaticHeading } from 'common/typography';

import { BlogArticleCategoryContainer, BlogArticleCategoryTag } from './styled';

export const BlogArticleCategory: React.FC<BlogArticleCategoryType> = ({ categories, position }) => {
  return (
    <BlogArticleCategoryContainer position={position || 'topLeft'}>
      {
        categories.map((category) => {
          return (
            <BlogArticleCategoryTag key={category}>
              <StaticHeading as="h5" $margin="0" $uppercase>
                {category}
              </StaticHeading>
            </BlogArticleCategoryTag>
          );
        })
      }
    </BlogArticleCategoryContainer>
  );
};

type BlogArticleCategoryType = {
  categories: string[];
  position?: 'bottomRight' | 'topLeft';
};
