import * as React from 'react';

import { useDevice } from 'services/hooks';
import ArrowIcon from 'vectors/internal/short_arrow.svg';
import { StaticButtonText } from 'common/typography';

import { IconContainer, ExtendedStaticButtonText, ButtonContainer, MobileReadMoreButton } from './styled';
import { ButtonMotion } from './motion';

export const BlogReadMoreButton: React.FC<BlogReadMoreButtonProps> = ({ path, onClick }) => {
  const { isMobile } = useDevice();

  if (isMobile) {
    return (
      <MobileReadMoreButton to={path} onClick={onClick}>
        <StaticButtonText>Read more</StaticButtonText>
      </MobileReadMoreButton>
    );
  }

  return (
    <ButtonContainer to={path} initial="init" whileHover="hover" animate="init" onClick={onClick}>
      <ExtendedStaticButtonText>
        Read more
      </ExtendedStaticButtonText>
      <IconContainer variants={ButtonMotion}>
        <ArrowIcon />
      </IconContainer>
    </ButtonContainer>
  );
};

type BlogReadMoreButtonProps = {
  path: string;
  onClick?: () => void;
};
