import * as i from 'types';
import * as React from 'react';

import { BlogArticle } from '..';

export const BlogOverviewArticleList: React.FC<BlogOverviewArticleListProps> = ({ articles }) => {
  return (
    <>
      {
        articles.map((article, index) => (
          <BlogArticle
            reversed={index % 2 === 0}
            article={article}
            key={article.id}
          />
        ))
      }
    </>
  );
};

type BlogOverviewArticleListProps = {
  articles: i.BlogArticleType[];
};
