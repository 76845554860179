import { Heading } from 'common/typography';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/utils';

export const BlogSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  margin-top: 46px;
  margin-bottom: 15vh;
  ${media.desktop`
    margin-top: 62px;
  `}
`;

export const ExtendedHeading = styled(motion(Heading))`
  text-align: center;
  padding: 2vh 0;
  font-size: 16vw;

  ${media.desktop`
    font-size: 8vw;
    padding: 15vh 0;
  `}
`;

export const BlogOverviewPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 5vh;
`;
