import * as i from 'types';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';

import { StaticHeading, StaticText } from 'common/typography/Static';
import { StaticButtonText } from 'common/typography';
import { BlogReturnButton } from 'modules/blog';
import { useDevice } from 'services/hooks';
import { BlogArticleDuration } from '../BlogArticleDuration';

import {
  HeaderContainer,
  ImageContainer,
  TitleContainer,
  MotionTitleContainer,
  ExtendedButton,
} from './styled';

export const BlogPageHeader: React.FC<i.BlogPageHeaderProps> = ({
  title,
  subtitle,
  bannerImage,
  duration,
  $alignImageRight,
  $hasRoundedCorners,
  ctaLink,
  hideMetaAndBackButton,
}) => {
  const { isMobile } = useDevice();

  const variant = {
    initial: {
      opacity: 0,
      x: 60,
    },
    animate: {
      delay: 0.2,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        type: 'tween',
        ease: 'easeInOut',
        bounce: 0,
      },
    },
  };

  return (
    <HeaderContainer $alignImageRight={$alignImageRight}>
      {isMobile && !hideMetaAndBackButton && (
        <BlogReturnButton />
      )}
      <AnimatePresence>
        <motion.div
          key="motionBlogDetail"
          initial={{ x: -60, y: 0 }}
          animate={{ x: 0, y: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <ImageContainer $hasRoundedCorners={$hasRoundedCorners}>
            <GatsbyImage
              image={getImage(bannerImage)!}
              objectFit="cover"
              className="blog_article_detail_banner_image"
              alt={title}
            />
          </ImageContainer>
        </motion.div>
        <MotionTitleContainer variants={variant} key="motionHeading">
          <TitleContainer>
            {!isMobile && !hideMetaAndBackButton && <BlogReturnButton />}
            {!hideMetaAndBackButton && duration && <BlogArticleDuration duration={duration} />}
            <StaticHeading as="h2" $margin="0" $uppercase>{title}</StaticHeading>
            <StaticText $size="xl" $margin="0">{subtitle}</StaticText>
            {ctaLink &&
              <ExtendedButton
                to={ctaLink}
                variant="primary"
                small
                type="button"
              >
                <StaticButtonText>
                  Shop now
                </StaticButtonText>
              </ExtendedButton>
            }
          </TitleContainer>
        </MotionTitleContainer>
      </AnimatePresence>
    </HeaderContainer>
  );
};
