import React from 'react';

import ArrowIcon from 'vectors/internal/short_arrow.svg';

import { IconContainer, ExtendedText, ButtonContainer } from './styled';
import { TextMotion, ButtonMotion } from './motion';

export const BlogReturnButton = () => (
  <ButtonContainer to="/" initial="init" whileHover="hover" animate="init">
    <IconContainer variants={ButtonMotion}>
      <ArrowIcon />
    </IconContainer>
    <ExtendedText variants={TextMotion}>
      Back to overview
    </ExtendedText>
  </ButtonContainer>
);
