import styled, { css } from 'styled-components';
import { Button } from 'common/interaction';
import { media } from 'styles/utils';

export const PaginationButton = styled(Button)<PaginationButtonProp>`
  height: 40px;
  width: 40px;
  padding: 0;
  transition: 0.3s ease;

  ${media.desktop`
    height: 48px;
    width: 48px;
  `}
`;

export const PaginationActionButton = styled(PaginationButton)`
  border: none;

  ${({ isPrevious }) => isPrevious && css`
    transform: rotate(180deg);
  `}

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.25;
  `}
`;

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationMoreButton = styled(PaginationButton)`
  pointer-events: none;
`;

export type PaginationButtonProp = {
  disabled?: boolean,
  isPrevious?: boolean,
}
