import styled from 'styled-components';

import { media } from 'styles/utils';
import { StaticText } from 'common/typography';

export const BlogPageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 80px;

  ${media.desktop`
    max-width: 1400px;
    margin: 80px auto;
    padding: 112px 16px 0;
  `}
`;

export const BlogPageBodyTextContainer = styled.div`
  width: 100%;

  ${media.desktop`
    max-width: 800px;
    margin: 0 auto;
  `}
`;

export const BlogPageBodyList = styled.ol`
  margin: 0;
  padding: 0 16px;

  li::marker {
    color: black;
  }

  ${StaticText} {
    margin: 0;
  }
`;
