import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { StaticHeading } from 'common/typography';

import { BlogInterestingArticle } from '..';
import { InterestingArticlesContainer, ArticlesListWrapper } from './styled';

export const BlogPageInterestingArticles: React.FC<BlogPageInterestingArticlesProps> = ({
  content,
}) => {
  if (!content) return null;

  return (
    <InterestingArticlesContainer>
      <StaticHeading as="h3" $margin="0 0 40px 0" $uppercase>Interesting articles</StaticHeading>
      <ArticlesListWrapper>
        {content?.map((article) => (
          <BlogInterestingArticle
            article={article}
            key={article.id}
          />
        ))}
      </ArticlesListWrapper>
    </InterestingArticlesContainer>
  );
};

type BlogPageInterestingArticlesProps = {
  content: i.BlogArticleType[];
};

export const query = graphql`
 fragment BlogPageInterestingArticlesFragment on ContentfulArticleWeb {
   interestingArticle {
     id
     title
     category
     previewText
     duration
     slug
     bannerImage {
      gatsbyImageData
      id
    }
   }
 }
`;
