import * as i from 'types';
import * as React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { StaticText } from 'common/typography';
import { BlogPageBodyTextContainer } from '../BlogPageBody/styled';

import { BlogMotionImageBlockContainer, BlogMotionImageWrapper } from './styled';

export const BlogMotionImageBlock: React.FC<i.BlogMotionImageBlockProps> = ({
  image, imageRef, size = 'default', margin, borderRadius, hoverable = true, clickable,
  slug, children, className }) => {
  const ContainerProps = {
    className,
    margin,
    borderRadius,
  };

  const ImageWrapperProps = {
    size,
    hoverable,
  };

  return (
    <>
      <BlogMotionImageBlockContainer {...ContainerProps}>
        {children}
        <BlogMotionImageWrapper {...ImageWrapperProps}>
          {clickable && slug &&
            <Link to={slug}>
              <GatsbyImage
                image={getImage(image)!}
                alt="thumbnail"
                objectFit="cover"
                className="motion_image"
              />
            </Link>
          }
          {!clickable && (
            <GatsbyImage
              image={getImage(image)!}
              alt="thumbnail"
              objectFit="cover"
              className="motion_image"
            />
          )}
        </BlogMotionImageWrapper>
      </BlogMotionImageBlockContainer>
      {imageRef && (
        <BlogPageBodyTextContainer>
          <StaticText $size="sm" $color="gray" $margin="14px 0 0" $isCentered>{imageRef}</StaticText>
        </BlogPageBodyTextContainer>
      )}
    </>
  );
};

export type BlogMotionImageBlockProps = {
  image: IGatsbyImageData;
  imageRef?: string[];
  size?: 'xs' | 'sm' | 'default';
  margin?: string;
  borderRadius?: string;
  hoverable?: boolean;
  clickable?: boolean;
  slug?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}
