import styled from 'styled-components';
import { motion } from 'framer-motion';

import {
  TextAndImageBlockExtraFieldsContainer,
  TextAndImageBlockExtraFieldsText,
} from 'modules/contentBlocks';
import { media } from 'styles/utils';
import { BlogMotionParallaxImageBlock } from '../BlogMotionParallaxImageBlock';


export const BlogArticleContainer = styled(TextAndImageBlockExtraFieldsContainer)`
  padding: 0 16px;
  align-items: center;
  margin: 0;
  margin-bottom: 6.5vh;

  ${media.desktop`
    gap: 5vw;
    padding: 0 19vw;
    margin-bottom: 12vh;
  `}
`;

export const BlogArticleTextBlock = styled(TextAndImageBlockExtraFieldsText)`
  gap: 16px;
  margin-top: 32px;

  ${media.desktop`
    width: auto;
    gap: 24px;
    margin-top: 0;
  `};
`;

export const BannerTextMotionContainer = styled(motion.div)`
  order: 1;

  ${media.desktop`
    width: 32%;
  `};
`;

export const BannerImageMotionContainer = styled(motion.div)`
  order: 2;

  ${media.desktop`
    width: 68%;
  `};
`;

export const BlogArticleBannerImage = styled(BlogMotionParallaxImageBlock)``;
