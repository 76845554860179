import styled from 'styled-components';

import { BlogArticleTextBlock, BlogArticleBannerImage } from 'modules/blog/BlogArticle/styled';
import { media } from 'styles/utils';

export const BlogFeaturedArticleContainer = styled.div`
  padding: 0 16px;
  align-items: stretch;
  margin: 0;
  margin-bottom: 6.5vh;

  ${media.desktop`
    padding: 0 12vw;
    margin-bottom: 12vh;
  `}
`;

export const BlogFeaturedArticleTextBlock = styled(BlogArticleTextBlock)`
  margin-top: 32px;

  ${media.desktop`
    width: 100%;
  `}
`;

export const BlogFeaturedArticleBannerImage = styled(BlogArticleBannerImage)`
  width: 100%;
`;
