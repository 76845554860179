import styled from 'styled-components';

import { media } from 'styles/utils';

export const InterestingArticlesContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 80px 0 100px 0;

  > h3 {
    text-align: center;
  }

  ${media.desktop`
    max-width: 1400px;
  `}
`;

export const ArticlesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 40px;

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: start;
  `}
`;
