import theme from 'styles/theme';

export const TextMotion = {
  init: {
    x: 0,
    y: '-50%',
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    x: -10,
    y: '-50%',
    color: `${theme.colors.white}`,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

export const ButtonMotion = {
  init: {
    width: 40,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    width: 180,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};
