import styled from 'styled-components';

import { media } from 'styles/utils';
import { BlogMotionImageBlock } from '../BlogMotionImageBlock';

export const TextWidthImageBlock = styled(BlogMotionImageBlock)`
  ${media.desktop`
    max-width: 800px;
  `}
`;
