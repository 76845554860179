import * as i from 'types';
import * as React from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';

import { useDevice } from 'services/hooks';
import { StaticHeading, StaticText } from 'common/typography';
import { BlogArticleDuration, BlogArticleCategory, BlogReadMoreButton } from '../';

import { featuredBlogArticleImageVariants, featuredBlogArticleItemVariants } from './motion';
import {
  BlogFeaturedArticleContainer,
  BlogFeaturedArticleTextBlock,
  BlogFeaturedArticleBannerImage,
} from './styled';

export const BlogFeaturedArticle: React.FC<i.BlogArticleProps> = ({ article }) => {
  const { isMobile } = useDevice();
  const controlsImage = useAnimation();
  const controlsText = useAnimation();
  const { bannerImage, category, duration, title, previewText, slug } = article;
  const detailPath = `/${slug}`;

  const onClickBlogFeaturedArticle = () => {
    controlsImage.start('exitImage');
    controlsText.start('exitText');
  };

  return (
    <BlogFeaturedArticleContainer>
      <AnimatePresence exitBeforeEnter>
        <motion.div key="blog_article_featured_image" variants={featuredBlogArticleImageVariants} animate={controlsImage}>
          <BlogFeaturedArticleBannerImage
            image={bannerImage}
            margin="0px"
            slug={detailPath}
            clickable
            onClick={onClickBlogFeaturedArticle}
          >
            <motion.div variants={featuredBlogArticleItemVariants} key="motionItem" exit="exit">
              <BlogArticleCategory categories={category} />
            </motion.div>
            <motion.div variants={featuredBlogArticleItemVariants}>
              <BlogArticleCategory categories={['Highlighted']} position="bottomRight" />
            </motion.div>
          </BlogFeaturedArticleBannerImage>
        </motion.div>
      </AnimatePresence>
      <motion.div key="blog_article_featured_text" variants={featuredBlogArticleImageVariants} animate={controlsText}>
        <BlogFeaturedArticleTextBlock>
          <BlogArticleDuration duration={duration} />
          <StaticHeading as={isMobile ? 'h4' : 'h3'} $margin="0" $uppercase>{title}</StaticHeading>
          <StaticText $size="lg" $margin="0">{previewText}</StaticText>
          <BlogReadMoreButton path={detailPath} onClick={onClickBlogFeaturedArticle} />
        </BlogFeaturedArticleTextBlock>
      </motion.div>
    </BlogFeaturedArticleContainer>
  );
};
