import * as React from 'react';
import { StaticButtonText } from 'common/typography';

import { BlogOverviewCategoryFilterTagButton, TagSeparator } from './styled';

export const BlogOverviewCategoryFilterTag: React.FC<BlogPageCategoryFilterTagProps> = ({ category, articleCount, active = false, onClick }) => {
  return (
    <BlogOverviewCategoryFilterTagButton
      variant={active ? 'primary' : 'transparent'}
      onClick={() => onClick(category)}
      small
    >
      <StaticButtonText $uppercase>{category}</StaticButtonText>
      <TagSeparator className="tag_separator" active={active} />
      <StaticButtonText>{articleCount}</StaticButtonText>
    </BlogOverviewCategoryFilterTagButton>
  );
};

type BlogPageCategoryFilterTagProps = {
  category: string,
  articleCount: number,
  active?: boolean,
  onClick: (category: string) => void;
};
