import styled from 'styled-components';

import { media } from 'styles/utils';

export const BlogOverviewCategoryFilterContainer = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8vh auto;

  ${media.desktop`
    flex-direction: row;
    margin: 10vh auto;
  `}
`;

export const CategoryTagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;
