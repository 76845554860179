import styled, { css } from 'styled-components';

export const BlogMotionImageBlockContainer = styled.div<BlogMotionImageBlockContainerProps>`
  border-radius: ${({ borderRadius }) => borderRadius || '16px'};
  overflow: hidden;
  position: relative;
  margin: ${({ margin }) => margin || '80px 0 0'};
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`;

type BlogMotionImageBlockContainerProps = {
  margin?: string,
  borderRadius?: string,
}

export const BlogMotionImageWrapper = styled.div<BlogMotionWrapperProps>`
  width: 100%;

  .motion_image {
    border-radius: inherit;
    width: 100%;

    ${({ hoverable }) => hoverable && css`
      transition: 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    `}
  }
`;

type BlogMotionWrapperProps = {
  hoverable?: boolean;
};
