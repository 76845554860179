export const ButtonMotion = {
  init: {
    marginLeft: '0px',
    width: '0',
    opacity: 0,
    transition: {
      duration: 0.15,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    marginLeft: '16px',
    opacity: 1,
    width: '100%',
    transition: {
      duration: 0.15,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};
