export const featuredBlogArticleImageVariants = {
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
  },
  animate: {
    y: 0,
    x: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
      type: 'spring',
      bounce: 0,
    },
  },
  exitImage: {
    opacity: 0,
    scale: 1.1,
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.5,
    },
  },
  exitText: {
    opacity: 0,
    y: '20%',
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.5,
    },
  },
};

export const featuredBlogArticleItemVariants = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
  },
};
