import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ContentBlocksButton } from 'modules/contentBlocks';
import { InternalLink } from 'common/interaction';
import { StaticButtonText } from 'common/typography';

export const ButtonContainer = styled(motion(InternalLink))`
  padding: 14px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.black};
  transition: 0.2s ease;

  &:hover {
    opacity: 0.6;
  }
`;

export const ExtendedStaticButtonText = styled(StaticButtonText)`
  margin: 0;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`;

export const IconContainer = styled(motion.div)`
  color: ${({ theme }) => theme.colors.white};
`;

export const MobileReadMoreButton = styled(ContentBlocksButton)`
  height: auto;
  padding: 14px 20px;
  text-transform: none;
  margin: 0;
`;
