import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const BlogArticleCategoryContainer = styled.div<BlogArticleCategoryContainerProps>`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  gap: 8px;

  ${({ position }) => position === 'bottomRight' && css`
    bottom: 16px;
    right: 16px;
    top: auto;
    left: auto;
  `}
`;

type BlogArticleCategoryContainerProps = {
  position: 'bottomRight' | 'topLeft';
};

export const BlogArticleCategoryTag = styled.div`
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.sand.light};
  border-radius: 100px;
  z-index: 2;
  text-transform: uppercase;

  ${media.desktop`
    font-size: 18px;
    left: 12px;
  `}
`;
