import styled from 'styled-components';

import { media } from 'styles/utils';
import { Button } from 'common/interaction';

export const BlogOverviewCategoryFilterTagButton = styled(Button)`
  height: 57px;
  border: 2px solid ${({ theme }) => theme.colors.black};

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  + button {
    margin-left: 0;
  }

  &:hover .tag_separator {
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${media.desktop`
    height: 74px;
  `}
`;

export const TagSeparator = styled.div<TagSeparatorProps>`
  height: 6px;
  width: 6px;
  margin: 0 8px;
  border-radius: 50%;
  transition: all 0.5s ease;
  background-color: ${({ theme, active }) => active ? theme.colors.white : theme.colors.black};
`;

type TagSeparatorProps = {
  active: boolean;
};
