import React from 'react';

import ShortArrow from 'vectors/internal/short_arrow.svg';
import { StaticButtonText } from 'common/typography';

import { IconContainer, PaginationActionButton, PaginationButton, PaginationMoreButton } from './styled';

export const BlogOverviewPaginationButton: React.FC<BlogOverviewPaginationButtonProp> = ({ pageNumber, active, onClick }) => {
  return (
    <PaginationButton variant={active ? 'primary' : 'transparent'} onClick={onClick}>
      <StaticButtonText>{pageNumber}</StaticButtonText>
    </PaginationButton>
  );
};

export const BlogOverviewPaginationActionButton: React.FC<BlogOverviewPaginationActionButtonProp> = ({ isPrevious, disabled, onClick }) => {
  return (
    <PaginationActionButton isPrevious={isPrevious} disabled={disabled} variant="transparent" onClick={onClick}>
      <IconContainer><ShortArrow /></IconContainer>
    </PaginationActionButton>
  );
};

export const BlogOverviewPaginationMoreButton: React.FC = () => {
  return (
    <PaginationMoreButton variant="transparent"><StaticButtonText>...</StaticButtonText></PaginationMoreButton>
  );
};

type BlogOverviewPaginationButtonProp = {
  pageNumber?: number;
  active?: boolean;
  onClick: () => void;
};

type BlogOverviewPaginationActionButtonProp = {
  isPrevious?: boolean;
  disabled?: boolean;
  onClick: () => void;
};
