import styled from 'styled-components';

import { media } from 'styles/utils';
import { BlogMotionImageBlock } from '../BlogMotionImageBlock';
import { BlogMotionParallaxImageBlock } from '../BlogMotionParallaxImageBlock';

export const DoubleImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 80px 0 0;

  ${media.desktop`
    flex-direction: row;
    align-items: baseline;
  `}
`;

export const BlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ImageBlock = styled(BlogMotionImageBlock)`
  width: 100%;
`;

export const ParallaxImageBlock = styled(BlogMotionParallaxImageBlock)`
  width: 100%;
`;
