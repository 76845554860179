import * as React from 'react';

import { BlogPageVideoContainer } from './styled';

export const BlogPageVideoBlock: React.FC<BlogPageVideoBlockProps> = ({ youtubeUrl, name }) => {
  const youtubeId = youtubeUrl.split('v=')[1].substring(0, 11);

  return (
    <BlogPageVideoContainer>
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={name}
      />
    </BlogPageVideoContainer>
  );
};

type BlogPageVideoBlockProps = {
  youtubeUrl: string;
  name: string;
};
