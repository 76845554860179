import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { BlogMotionParallaxImageBlock } from '../';

import { TextWidthImageBlock } from './styled';

export const BlogPageTextWidthImageBlock: React.FC<BlogPageTextWidthImageBlockProps> = ({
  image, imageRef, parallax,
}) => {
  const ImageBlockProps = {
    image,
    imageRef,
    margin: '80px 0 0',
    hoverable: false,
  };

  if (parallax) {
    return <TextWidthImageBlock as={BlogMotionParallaxImageBlock} {...ImageBlockProps} size="sm" />;
  } else {
    return <TextWidthImageBlock {...ImageBlockProps} size="sm" />;
  }

};

type BlogPageTextWidthImageBlockProps = {
  image: IGatsbyImageData;
  imageRef: string[];
  parallax?: boolean;
}
