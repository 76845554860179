import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { Button } from 'common/interaction';
import { media } from 'styles/utils';

export const MotionTitleContainer = styled(motion.div)`
  ${media.desktop`
    align-self: center;
    margin-top: 0;
  `}
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 81.5px 16px 0 16px;

  ${media.desktop<HeaderContainerProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1400px;
    margin: 0 auto;
    padding: 168px 0 0;
    grid-gap: 64px;

    ${({ $alignImageRight }) => $alignImageRight && css`
      >:first-child {
        order: 1;
      }
    `}
  `}
`;

type HeaderContainerProps = {
  $alignImageRight?: boolean;
};

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
  width: fit-content;
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  ${({ $hasRoundedCorners }) => $hasRoundedCorners && css`
    border-radius: 16px;
  `}

  ${media.desktop<ImageContainerProps>`
    margin: 0 0 -20px 0;
    height: 64vh;

    ${({ $hasRoundedCorners }) => $hasRoundedCorners && css`
      height: 60vh;
      margin: 0;
      border-radius: 32px;
    `}
  `}

  .blog_article_detail_banner_image {
    border-radius: inherit;
    min-height: 100%;
    width: 100%;
  }
`;

type ImageContainerProps = {
  $hasRoundedCorners?: boolean;
};

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 26px;
  margin-top: 26px;
`;
