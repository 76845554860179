import styled from 'styled-components';

import { media } from 'styles/utils';

export const BlogArticleDurationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BlogArticleDurationTimeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 100%;
  height: 8vw;
  width: 8vw;
  margin-right: 1vw;
  flex-shrink: 0;
  font-size: 2vw;

  ${media.tablet`
    height: 4vw;
    width: 4vw;
  `}
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;
`;
