import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { StaticText } from 'common/typography';

import { DoubleImageContainer, BlockWrapper, ImageBlock, ParallaxImageBlock } from './styled';

export const BlogPageDoubleImageBlock: React.FC<BlogPageDoubleImageBlockProps> = ({ images, imageRefs, parallax }) => {
  const isImageRefsSame = imageRefs && imageRefs[0] === imageRefs[1];
  const ImageBlockComponent = parallax ? ParallaxImageBlock : ImageBlock;
  return (
    <>
      <DoubleImageContainer>
        {images.map((image, index) => {
          return (
            <BlockWrapper key={`image_${index}_${image?.images?.sources?.[0].srcSet}`}>
              <ImageBlockComponent
                image={image}
                imageRef={isImageRefsSame ? undefined : [imageRefs[index]]}
                size="sm"
                margin="0"
                hoverable={false}
              />
            </BlockWrapper>
          );
        })}
      </DoubleImageContainer>
      {isImageRefsSame && <StaticText $size="sm" $color="gray" $margin="14px 0 0" $isCentered>{imageRefs[0]}</StaticText>}
    </>
  );
};

type BlogPageDoubleImageBlockProps = {
  images: IGatsbyImageData[];
  imageRefs: string[];
  parallax: boolean;
}
